import React from 'react';
import { Link, PageProps } from 'gatsby';
import styled from 'styled-components';

import { rhythm, scale } from '../../utils/typography';
import LayoutHeader from './layout-header';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';

declare var __PATH_PREFIX__: string;

interface Props {
  children: React.ReactNode;
}

const Layout = (props: Props) => {
  const { children } = props;

  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
    },
  });

  return (
    <Wrapper>
      <ThemeProvider theme={darkTheme}>        
        <div
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            // padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          {/* <header>{header}</header> */}
          <LayoutHeader />
          <main>{children}</main>
        </div>
        <Footer>© {new Date().getFullYear()}, Abdurrachman Habibi</Footer>
      </ThemeProvider>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-height: 100vh;
`;

const Footer = styled.footer`
  text-align: center;
  margin: 24px;
`;

export default Layout;
